import { formatNumber, mathRound } from "../../utils/number";
import "./AggregatedTable.scss";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const sumColumnValues = ({ table, column }) => {
  return mathRound(
    table
      .getFilteredRowModel()
      .rows.reduce(
        (total, row) =>
          total +
          (column.columnDef.accessorFn
            ? column.columnDef.accessorFn(row.original)
            : row.getValue(column.columnDef.accessorKey)),
        0,
      ),
  );
};

const columns = [
  columnHelper.accessor("returnReason", {
    header: () => <span>Reason</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("quantity", {
    header: () => <span>Quantity</span>,
    cell: (info) => info.getValue(),
    footer: sumColumnValues,
  }),
  columnHelper.accessor(
    (row) => {
      return typeof row.total?.amount === "number" &&
        typeof row.tax?.amount === "number"
        ? (row.total?.amount * 100 - row.tax?.amount * 100) / 100
        : "";
    },
    {
      id: "net-amount",
      header: () => <span>Net Amount</span>,
      cell: (info) => formatNumber(info.getValue()),
      footer: (info) => formatNumber(sumColumnValues(info)),
    },
  ),
  columnHelper.accessor((row) => row.tax?.amount, {
    id: "tax-amount",
    header: () => <span>Tax Amount</span>,
    cell: (info) => formatNumber(info.getValue()),
    footer: (info) => formatNumber(sumColumnValues(info)),
  }),
  columnHelper.accessor((row) => row.total?.amount, {
    id: "gross-amount",
    header: () => <span>Gross Amount</span>,
    cell: (info) => formatNumber(info.getValue()),
    footer: (info) => formatNumber(sumColumnValues(info)),
  }),
  columnHelper.accessor((row) => row.total?.currencyCode, {
    id: "currency",
    header: () => <span>Currency</span>,
    cell: (info) => info.getValue(),
  }),
];

export default function AggregatedTable({ data }) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="aggregated-table-root">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}
