import DatePicker from "react-datepicker";
import "./DateRange.scss";

function DateRange({ startDate, setStartDate, endDate, setEndDate, disabled }) {
  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
        maxDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
      />
    </>
  );
}

export default DateRange;
