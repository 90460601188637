import "./Home.scss";
import { useTranslation } from "react-i18next";
import { ReturnContext } from "../../context/ReturnContext";
import Login from "../../components/Login/Login";
import Return from "../../components/Return/Return";
import Success from "../../components/Success/Success";
import { useContext, useEffect } from "react";

function Home() {
  const { returnStep } = useContext(ReturnContext);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var lang = "it";
    if (window.location.search) {
      window.location.search
        .split("?")[1]
        .split("&")
        .forEach((arg) => {
          const splitArg = arg.split("=");
          if (splitArg.length > 0 && splitArg[0] === "lang") {
            lang = splitArg[1];
          }
        });
      i18n.changeLanguage(lang);
    }
  }, []);

  const contentState = (step) => {
    if (step === "Success") {
      return <Success />;
    } else {
      return (
        <>
          <h1 className="returns-app__title">{t("title")}</h1>
          <p className="returns-app__subtitle">
            {t("subtitle.subtitle_link_prefix")}{" "}
            <a href={t("subtitle.subtitle_link_url")}>
              {t("subtitle.subtitle_link_text")}
            </a>
            {t("subtitle.subtitle_link_suffix")}
          </p>
          {returnStep === "Login" ? <Login /> : null}
          {returnStep === "Return" ? <Return /> : null}
        </>
      );
    }
  };

  return (
    <div className="returns-app__container">{contentState(returnStep)}</div>
  );
}

export default Home;
