import React, { Component } from 'react';
import axios from 'axios';


const ReturnContext = React.createContext();
axios.defaults.baseURL = process.env.REACT_APP_RMA_SERVER_HOST;

class ReturnProvider extends Component {
  state = {
    returnStep: 'Login',
    returnSelectionStep: 'select',
    axios: axios,
    orderGID: '',
    orderEmail: '',
    products: [],
    returnProducts: [],
    returnReasons: process.env.REACT_APP_RETURN_REASONS.trim().split(','),
    serverHost: process.env.REACT_APP_SERVER_HOST,
    apiVersion: process.env.REACT_APP_API_VERSION
  };

  setReturnStep = (newStep) => {
    this.setState({ returnStep: newStep });
  };

  setReturnSelectionStep = (newReturnSelectStep) => {
    this.setState({ returnSelectionStep: newReturnSelectStep });
  };

  setProducts = (newProducts) => {
    this.setState({ products: newProducts });
  };

  setOrderGID = (newOrderGID) => {
    this.setState({ orderGID: newOrderGID });
  };

  setOrderEmail = (newOrderEmail) => {
    this.setState({ orderEmail: newOrderEmail });
  };

  updateReturnProduct = (newProduct) => {
    const newReturnProducts = [...this.state.returnProducts];
    const currentProductIndex = newReturnProducts.findIndex(product => product.fulfillmentLineItemId === newProduct.fulfillmentLineItemId);
    if (currentProductIndex >= 0) {
      newReturnProducts.splice(currentProductIndex, 1, newProduct);
    } else {
      newReturnProducts.push(newProduct);
    }
    this.setState({ returnProducts: newReturnProducts });
  };

  removeReturnProduct = (oldProduct) => {
    const newReturnProducts = [...this.state.returnProducts];
    const currentProductIndex = newReturnProducts.findIndex(product => product.fulfillmentLineItemId === oldProduct.fulfillmentLineItemId);
    if (currentProductIndex >= 0) {
      newReturnProducts.splice(currentProductIndex, 1);
      this.setState({ returnProducts: newReturnProducts });
    }
  };

  render() {
    return (
      <ReturnContext.Provider
        value={{
          ...this.state,
          setReturnStep: this.setReturnStep,
          setReturnSelectionStep: this.setReturnSelectionStep,
          setProducts: this.setProducts,
          setOrderGID: this.setOrderGID,
          setOrderEmail: this.setOrderEmail,
          updateReturnProduct: this.updateReturnProduct,
          removeReturnProduct: this.removeReturnProduct
        }}
      >
        {this.props.children}
      </ReturnContext.Provider>
    );
  }
}

export { ReturnContext };
export default ReturnProvider;
