import "./Statistics.scss";
import DateRange from "../../components/DateRange/DateRange";
import CountryPicker, {
  countries,
} from "../../components/CountryPicker/CountryPicker";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useContext, useState } from "react";
import { ReturnContext } from "../../context/ReturnContext";
import {
  getOneWeekAgoDate,
  getTodayDate,
  toDateString,
} from "../../utils/date";
import AggregatedReturns from "../../components/AggregatedReturns/AggregatedReturns";
import Spinner from "../../components/Spinner/Spinner";
import StopWatch from "../../components/StopWatch/StopWatch";
import { useAuth0 } from "@auth0/auth0-react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import AuthProvider from "../../providers/Auth/Auth";
import AuthenticateAxiosRequests from "../../components/AuthenticateAxiosRequests/AuthenticateAxiosRequests";

const queryClient = new QueryClient();

function getOrdersReturnedFetcher({ queryKey }) {
  const [, { axios, apiVersion, startDate, endDate, country }] = queryKey;

  return axios.request({
    method: "POST",
    url: `/api/${apiVersion}/getReturns`,
    headers: {
      "content-type": "application/json",
    },
    data: {
      pageSize: 1000000,
      startDate: toDateString(startDate),
      endDate: toDateString(endDate),
      country,
    },
  });
}

function Orders() {
  const [startDate, setStartDate] = useState(getOneWeekAgoDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [country, setCountry] = useState(countries[0].value);
  const [paused, setPaused] = useState(false);

  const { axios, apiVersion } = useContext(ReturnContext);

  const onClick = () => {
    setPaused(false);
  };

  const { isLoading, isError, data, error, isIdle, isSuccess } = useQuery({
    queryKey: [
      "ordersReturned",
      {
        axios,
        apiVersion,
        startDate,
        endDate,
        country,
      },
    ],
    queryFn: getOrdersReturnedFetcher,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !paused,
  });

  if (error) {
    console.error(error);
  }

  return (
    <div>
      <div className="flex items-center mt-2">
        <DateRange
          startDate={startDate}
          setStartDate={(...args) => {
            setPaused(true);
            setStartDate(...args);
          }}
          endDate={endDate}
          setEndDate={(...args) => {
            setPaused(true);
            setEndDate(...args);
          }}
          disabled={isLoading}
        />
        <CountryPicker
          className="country-picker"
          value={country}
          onChange={(e) => {
            setPaused(true);
            setCountry(e.target.value);
          }}
          disabled={isLoading}
        />
        {!isSuccess && (
          <button
            disabled={isLoading}
            className="load-data-button mx-4 px-4 py-1"
            onClick={onClick}
          >
            Load Data
          </button>
        )}
        {isLoading && <Spinner />}
        {isLoading && <StopWatch className="ml-4" />}
      </div>
      {isError && "Something went wrong! Please retry later."}
      {data?.data?.returnedOrdersLength > 0 ? (
        <AggregatedReturns data={data.data.returnedOrders} />
      ) : (
        !isLoading &&
        !isError &&
        !isIdle && <div className="my-2">No data found</div>
      )}
    </div>
  );
}

function Statistics() {
  return (
    <AuthProvider>
      <AuthenticateAxiosRequests />
      <HeaderBar />
      <StatisticsContent />
    </AuthProvider>
  );
}

function StatisticsContent() {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <div className="statistics statistics__container">
      <div className="statistics__title">Statistics</div>
      <div className="statistics__subtitle">Aggregated returns by reason</div>
      <div className="statistics__content">
        <QueryClientProvider client={queryClient}>
          <Orders />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </div>
      <div className="statistics__disclaimer">
        ℹ️ The time required to obtain the data varies depending on the quantity
        of returns present. For a monthly report, it could take up to 3 minutes.
      </div>
    </div>
  );
}

export default Statistics;
