import { useState } from "react";
import { useEffect } from "react";

export default function StopWatch({ className }) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervalId;

    // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
    intervalId = setInterval(() => setTime(time + 1), 10);

    return () => clearInterval(intervalId);
  }, [time]);

  // Hours calculation
  // const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;
  return (
    <span className={className}>
      {/* {hours}: */}
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}:
      {milliseconds.toString().padStart(2, "0")}
    </span>
  );
}
