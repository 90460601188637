import "./Avatar.scss"

export default function Avatar({ user }) {
  if (!user) {
    return null;
  }

  const { name, picture } = user;
  return (
    <div className="avatar">
      <img src={picture} alt={name} className="profile" />
      <div className="name">{name}</div>
    </div>
  );
}
