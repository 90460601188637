import "./HeaderBar.scss";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../LoginButton/LoginButton";
import LogoutButton from "../LogoutButton/LogoutButton";
import Avatar from "../Avatar/Avatar";

export default function HeaderBar() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  return (
    <div className="header-bar">
      {!isLoading && !isAuthenticated && <LoginButton />}
      {!isLoading && isAuthenticated && (
        <>
          <Avatar user={user} />
          <LogoutButton />
        </>
      )}
    </div>
  );
}
