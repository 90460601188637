import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import { ReturnContext } from "../../context/ReturnContext";
import { useEffect } from "react";

export default function AuthenticateAxiosRequests() {
  const { axios } = useContext(ReturnContext);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    axios.interceptors.request.use(async function (config) {
      const accessToken = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    });
  }, [axios.interceptors.request, getAccessTokenSilently]);
}
