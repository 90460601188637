import './Return.scss';
import { ReturnContext } from '../../context/ReturnContext';
import React, { useContext, useRef, useState, useEffect } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useTranslation } from 'react-i18next';

function Return() {
  const { t, i18n } = useTranslation();
  const { axios, apiVersion, products, setReturnStep, returnSelectionStep, setReturnSelectionStep, returnProducts, orderGID, orderEmail } = useContext(ReturnContext);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [returningItems, setReturningItems] = useState(0);
  const errorLabel = useRef();
  const submitButton = useRef();
  const cancelButton = useRef();
  useEffect(() => {
    var lang = 'it';
    if (window.location.search) {
      window.location.search.split('?')[1].split('&').forEach(arg => {
        const splitArg = arg.split('=');
        if (splitArg.length > 0 && splitArg[0] === 'lang') {
          lang = splitArg[1];
        }
      });
      i18n.changeLanguage(lang);
    }
  }, []);

  const handleSubmit = () => {
    if (returnSelectionStep === 'select') {
      setReturnSelectionStep('confirm');
    } else if (returnSelectionStep === 'confirm') {
      if (orderGID !== '' && returnProducts.length > 0) {
        submitButton.current.classList.add('loading');
        axios.request(
          {
            method: 'POST',
            url: `/api/${apiVersion}/returnRequest`,
            headers: {
              'content-type': 'application/json'
            },
            data: {
              orderId: orderGID,
              email: orderEmail,
              returnLineItems: returnProducts
            }
          })
          .then(res => {
            const productErrors = [];
            res.data.forEach(lineItem => {
              if (lineItem.userErrors.length > 0) {
                productErrors.push(lineItem);
              }
            });

            if (productErrors.length > 0) {
              const errorMessage = `${t('return.errors.general')}`;

              errorLabel.current.innerText = errorMessage;
              errorLabel.current.classList.remove('hidden');
              setTimeout(() => {
                errorLabel.current.classList.add('hidden');
              }, 10000);
            } else {
              setReturnStep('Success');
            }
          })
          .catch(error => {
            var errorMessage = t('return.errors.general');
            if (error.response?.data?.errors[0].type === 'ORDER_NOT_EXISTS') {
              errorMessage = t('return.errors.order_not_exists');
            } else if (error.response?.data?.errors[0].type === 'EMAIL_NOT_CORRESPONDING') {
              errorMessage = t('return.errors.email_not_corresponding');
            } else {
              error.response?.data?.errors.forEach(error => {
                if (error.type === 'field') {
                  errorMessage = t(`return.errors.fields.${error.path}`);
                }
              });
            }
            errorLabel.current.innerText = errorMessage;
            errorLabel.current.classList.remove('hidden');
            setTimeout(() => {
              errorLabel.current.classList.add('hidden');
            }, 10000);
          })
          .finally(() => {
            submitButton.current.classList.remove('loading');
            cancelButton.current.disabled = false;
          });
      } else {
        if (orderGID !== '') {
          setReturnSelectionStep('select');
          setReturnStep('Login');
        } else {
          setReturnSelectionStep('select');
        }
      }
    }
  };

  const handleCancel = () => {
    if (returnSelectionStep === 'select') {
      setReturnStep('Login');
    } else if (returnSelectionStep === 'confirm') {
      setReturnSelectionStep('select');
      setDisableSubmit(false);
    }
  };

  const checkCardError = () => {
    const selectedProducts = document.querySelectorAll('.product-card__checkbox input[type="checkbox"]:checked');
    const cardInputErrors = document.querySelectorAll('.product-card__inputs select.error,.product-card__inputs input[type="text"].error');
    if (cardInputErrors.length > 0 || selectedProducts.length <= 0) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  };

  return (
    <div className='product-selection__container'>
      <div className='product-selection__step-description'>
        {t(`return.step_description.${returnSelectionStep}`)}
      </div>
      <div className={returnSelectionStep === 'confirm' ? 'product-selection__list product-selection__list--confirm' : 'product-selection__list'}>
        {products.length <= 0 ?
          setReturnStep('Login') :
          products.map(product => {
            return <ProductCard key={product.node.fulfillmentLineItem.id} product={product.node} returningItems={returningItems} setReturningItems={setReturningItems} checkCardError={checkCardError} />
          })
        }
      </div>
      <div className='product-selection__actions'>
        <div className='product-selection__counter'>
          <span className='product-selection-counter__label'>
            {t('return.return_counter_label')}
          </span>
          <span className='product-selection-counter__value'>
            {returningItems} {returningItems === 1 ? t('return.return_counter_item') : t('return.return_counter_items')}
          </span>
        </div>
        <div className='product-selection__info-label'>
          {t('return.info_label')}
        </div>
        <div ref={errorLabel} className='product-selection__error-label hidden'></div>
        <div className='product-selection__buttons'>
          <button ref={submitButton} className='btn-primary product-selection__submit' onClick={handleSubmit} disabled={disableSubmit}>{returnSelectionStep === 'select' ? t('return.proceed_label') : t('return.submit_label')}</button>
          <button ref={cancelButton} className='btn-outline product-selection__cancel' onClick={handleCancel}>{returnSelectionStep === 'select' ? t('return.cancel_label') : t('return.back_label')}</button>
        </div>
      </div>
    </div>
  )
}

export default Return
