import { CSVLink } from "react-csv";
import "./DownloadCSV.scss";

const headers = [
  { label: "Order Name", key: "orderName" },
  { label: "Shipping Country", key: "shippingCountry" },
  { label: "Order Return Status", key: "orderReturnStatus" },
  { label: "Return Name", key: "returnName" },
  { label: "Return Status", key: "returnStatus" },
  { label: "Return Reason", key: "returnReason" },
  { label: "SKU", key: "sku" },
  { label: "Variant", key: "variantTitle" },
  { label: "Product Name", key: "productName" },
  { label: "Quantity", key: "quantity" },
  { label: "Total", key: "total.amount" },
  { label: "Tax", key: "tax.amount" },
  { label: "Currency", key: "total.currencyCode" },
  { label: "PresentmentTotal", key: "presentmentTotal.amount" },
  { label: "PresentmentTax", key: "presentmentTax.amount" },
  { label: "PresentmentCurrency", key: "presentmentTotal.currencyCode" },
];

export default function DownloadCSV({ data }) {
  return (
    <div className="mt-4">
      <CSVLink
        data={data}
        headers={headers}
        className="download-csv-link py-2 px-4"
        filename={"returns.csv"}
      >
        Download csv
      </CSVLink>
    </div>
  );
}
