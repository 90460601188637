import { useMemo } from "react";
import AggregatedTable from "../AggregatedTable/AggregatedTable";
import DownloadCSV from "../DownloadCSV/DownloadCSV";
import { mathRound } from "../../utils/number";

export default function AggregatedReturns({ data }) {
  const flatData = useMemo(() => {
    return data.reduce((acc, order) => {
      return [
        ...acc,
        ...order.returns?.reduce((acc, currentReturn) => {
          return [
            ...acc,
            ...currentReturn.returnLineItems.map((returnLineItem) => ({
              orderName: order.name,
              orderReturnStatus: order.returnStatus,
              shippingCountry: order.country,
              returnName: currentReturn.name,
              returnStatus: currentReturn.status,
              ...returnLineItem,
              name: undefined,
              productName: returnLineItem.name,
            })),
          ];
        }, []),
      ];
    }, []);
  }, [data]);

  const aggregatedData = useMemo(() => {
    const aggregated = [
      ...flatData
        .reduce((acc, returnedProduct) => {
          const key = [returnedProduct.returnReason].join("__");

          const item = acc.get(key) || {
            returnReason: returnedProduct.returnReason,
            quantity: 0,
            total: {
              amount: 0,
              currencyCode: returnedProduct.total.currencyCode,
            },
            tax: { amount: 0, currencyCode: returnedProduct.tax.currencyCode },
          };

          item.quantity += returnedProduct.quantity;

          item.total.amount = mathRound(
            item.total.amount + returnedProduct.total.amount,
          );

          item.tax.amount = mathRound(
            item.tax.amount + returnedProduct.tax.amount,
          );

          return acc.set(key, item);
        }, new Map())
        .values(),
    ];

    return aggregated;
  }, [flatData]);

  return (
    <>
      <DownloadCSV data={flatData} />
      <AggregatedTable data={aggregatedData} />
    </>
  );
}
