import { Auth0Provider } from "@auth0/auth0-react";

export default function AuthProvider({ children }) {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin + window.location.pathname,
      }}
    >
      {children}
    </Auth0Provider>
  );
}
