import './ProductCard.scss'
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReturnContext } from '../../context/ReturnContext';

function ProductCard({ product, returningItems, setReturningItems, checkCardError }) {
  const { returnSelectionStep, returnReasons, updateReturnProduct, removeReturnProduct } = useContext(ReturnContext);
  const { t, i18n } = useTranslation();
  const [productReturn, setProductReturn] = useState({
    customerNote: '',
    fulfillmentLineItemId: product.fulfillmentLineItem.id,
    quantity: 1,
    returnReason: ''
  });
  const checkbox = useRef();
  const productQty = useRef();
  const reasonSelect = useRef();
  const reasonNote = useRef();
  useEffect(() => {
    var lang = 'it';
    if (window.location.search) {
      window.location.search.split('?')[1].split('&').forEach(arg => {
        const splitArg = arg.split('=');
        if (splitArg.length > 0 && splitArg[0] === 'lang') {
          lang = splitArg[1];
        }
      });
      i18n.changeLanguage(lang);
    }
  }, []);

  var metfieldIndex = 0;
  if (i18n.language === 'it') {
    metfieldIndex = 1;
  } else if (i18n.language === 'fr') {
    metfieldIndex = 2;
  } else if (i18n.language === 'de') {
    metfieldIndex = 3;
  }

  useEffect(() => {
    if (
      (productReturn.returnReason !== '') &&
      ((productReturn.returnReason !== 'OTHER') || (productReturn.returnReason === 'OTHER' && productReturn.customerNote !== '')) &&
      (productReturn.quantity >= 1 && productReturn.quantity <= product.quantity)
    ) {
      updateReturnProduct(productReturn);
    } else {
      removeReturnProduct(productReturn);
    }
  }, [productReturn]);

  const updateContext = () => {
    if (checkbox.current && reasonSelect.current && reasonNote.current && productQty.current) {
      if (checkbox.current.checked) {
        setProductReturn({
          customerNote: reasonNote.current.value,
          fulfillmentLineItemId: product.fulfillmentLineItem.id,
          returnReason: reasonSelect.current.value,
          quantity: parseInt(productQty.current.value)
        });

        if (reasonSelect.current.value !== 'OTHER') {
          reasonNote.current.classList.remove('error');
        } else if (reasonNote.current.value !== '') {
          reasonNote.current.classList.add('error');
        } else {
          reasonNote.current.classList.remove('error');
        }
      } else {
        reasonSelect.current.classList.remove('error');
        reasonNote.current.classList.remove('error');
      }
    } else {
      removeReturnProduct(productReturn);
    }
    checkCardError();
  };

  const onCheckboxChange = () => {
    if (checkbox.current) {
      if (checkbox.current.checked) {
        setReturningItems(returningItems + productReturn.quantity);
      } else {
        setReturningItems(returningItems - productReturn.quantity);
      }
      updateContext();
    }
  };

  const changeQty = (e) => {
    if (e.target.dataset?.amount) {
      const amount = parseInt(e.target.dataset.amount);
      const currentValue = parseInt(productQty.current.value);
      const newValue = currentValue + amount;
      setReturningItems(returningItems + amount);
      productQty.current.value = newValue;
      updateContext();
    }
  };

  return (
    <div className={returnSelectionStep === 'confirm' && checkbox.current?.checked === false ? 'product-card__container hidden' : 'product-card__container'}>
      <div className='product-card__checkbox'>
        <input id={'checkbox--' + product.fulfillmentLineItem.id} ref={checkbox} type='checkbox' onChange={onCheckboxChange} disabled={returnSelectionStep === 'confirm' ? true : false} />
        <label for={'checkbox--' + product.fulfillmentLineItem.id}></label>
      </div>
      <div className='product-card__image'>
        <figure>
          <img src={product.fulfillmentLineItem.lineItem.image.url} alt="" />
        </figure>
      </div>
      <div className='product-card__details'>
        <div className='product-card__meta'>
          <div className='product-card__title'>{product.fulfillmentLineItem.lineItem.title}</div>
          <div className='product-card__price'>
            <FormattedNumber
              value={product.fulfillmentLineItem.lineItem.discountedTotalSet.presentmentMoney.amount}
              style="currency"
              currency={product.fulfillmentLineItem.lineItem.discountedTotalSet.presentmentMoney.currencyCode}
            />
          </div>
          <div className='product-card__line-info'>
            {
              product.fulfillmentLineItem.lineItem.product.metafield ?
                <div className='product-card-line__single-info'>{
                  !!product.fulfillmentLineItem.lineItem.product.metafield.value.split('|||')[0].split('|').length ? product.fulfillmentLineItem.lineItem.product.metafield.value.split('|||')[0].split('|')[metfieldIndex] : ''
                }</div> :
                null
            }
            {
              product.fulfillmentLineItem.lineItem.variant.selectedOptions.map(variantOption => {
                return <div key={`Option--${variantOption.name}`} className='product-card-line__single-info'>{variantOption.value}</div>
              })
            }
            <div className='product-card-line__single-info'>{t('product_card.qty_label')} {product.quantity}</div>
          </div>
        </div>

        <div className={checkbox.current?.checked && returnSelectionStep === 'select' ? 'product-card__inputs' : 'product-card__inputs hidden'}>
          <div className='product-card__qty-selector'>
            <button className='product-card__qty-change' data-amount='-1' onClick={changeQty} disabled={productReturn.quantity <= 1 ? true : false}>
              <svg width="13" height="2" viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="1" x2="11.0685" y2="1" stroke="#0F0F0F" strokeWidth="round" strokeLinecap="round" />
              </svg>
            </button>
            <input ref={productQty} type='text' min='1' max={product.quantity} defaultValue='1' readOnly />
            <button className='product-card__qty-change' data-amount='1' onClick={changeQty} disabled={productReturn.quantity >= product.quantity ? true : false}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1.0589V7.0589M7 7.0589V13.0589M7 7.0589H13M7 7.0589H1" stroke="#0F0F0F" strokeWidth="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <select ref={reasonSelect} onChange={updateContext}>
            {returnReasons.map(returnReason => {
              return <option key={returnReason} value={returnReason}>{t(`return.return_inputs.reasons.${returnReason.toLowerCase()}`)}</option>
            })}
          </select>
          <input ref={reasonNote} type='text' placeholder={t('return.return_inputs.note_label')} onBlur={updateContext} />
        </div>
        <div className={checkbox.current?.checked && returnSelectionStep === 'confirm' ? 'product-card__inputs-recap' : 'product-card__inputs-recap hidden'}>
          <div className='product-card__recap'>
            <p className='product-card-recap__title'>{t('product_card.qty_label')}</p>
            <p className='product-card-recap__content'>{productReturn.quantity}</p>
          </div>
          <div className='product-card__recap'>
            <p className='product-card-recap__title'>{t('return.return_inputs.reason_label')}</p>
            <p className='product-card-recap__content'>{t(`return.return_inputs.reasons.${reasonSelect.current?.value.toLowerCase()}`)}</p>
          </div>
          <div className='product-card__recap'>
            <p className='product-card-recap__title'>{t('return.return_inputs.note_label')}</p>
            <p className='product-card-recap__content'>{reasonNote.current?.value !== '' ? reasonNote.current?.value : '---'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
