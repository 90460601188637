import './Login.scss';
import React, { createRef, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReturnContext } from '../../context/ReturnContext';

function Login() {
  const { axios, apiVersion, setProducts, setOrderGID, setOrderEmail, setReturnStep } = useContext(ReturnContext);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t, i18n } = useTranslation();
  const email = createRef();
  const orderNumber = createRef();
  const errorElement = createRef();
  useEffect(() => {
    var lang = 'it';
    if (window.location.search) {
      window.location.search.split('?')[1].split('&').forEach(arg => {
        const splitArg = arg.split('=');
        if (splitArg.length > 0 && splitArg[0] === 'lang') {
          lang = splitArg[1];
        }
      });
      i18n.changeLanguage(lang);
    }
  }, []);

  const patternCheck = (e) => {
    e.target.classList.remove('error');
    errorElement.current.classList.add('hidden');
    if (orderNumber.current && email.current) {
      const orderNumberTest = /^([A-Za-z0-9]{1,})$/.test(orderNumber.current.value);
      const emailTest = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.current.value);
      if (orderNumberTest && emailTest) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  };

  const handleSubmit = () => {
    if (orderNumber.current && email.current && orderNumber.current.value && email.current.value) {
      const submitButton = document.querySelector('.login-form__submit');
      if (submitButton) {
        submitButton.classList.add('loading');
      }
      axios.request(
        {
          method: 'POST',
          url: `/api/${apiVersion}/getReturnableProducts`,
          headers: {
            'content-type': 'application/json'
          },
          data: {
            email: email.current.value,
            orderNumber: orderNumber.current.value
          }
        })
        .then(res => {
          if (res.data) {
            if (typeof res.data.orderGID !== 'undefined') {
              if (typeof res.data.returnableProducts !== 'undefined' && res.data.returnableProducts.length > 0) {
                setOrderGID(res.data.orderGID);
                setOrderEmail(email.current.value);
                setProducts(res.data.returnableProducts);
                setReturnStep('Return');
              } else {
                setErrorMessage(t('login.errors.no_returnable_items'));
                errorElement.current.classList.remove('hidden');
              }
            } else {
              setErrorMessage(t('login.errors.general'));
              errorElement.current.classList.remove('hidden');
            }
          } else {
            setErrorMessage(t('login.errors.general'));
            errorElement.current.classList.remove('hidden');
          }
          if (submitButton) {
            submitButton.classList.remove('loading');
          }
        })
        .catch(error => {
          setErrorMessage(t('login.errors.general'));
          if (error.response?.data?.errors?.length > 0) {
            if (error.response.data.errors[0].type === 'EMAIL_NOT_CORRESPONDING') {
              setErrorMessage(t('login.errors.not_corresponding'));
            } else if (error.response.data.errors[0].type === 'ORDER_NOT_EXISTS') {
              setErrorMessage(t('login.errors.order_not_exists'));
            } else if (error.response.data.errors[0].type === 'OVERTIME_ERROR') {
              setErrorMessage(t('login.errors.overtime'));
            } else if (error.response.data.errors[0].type === 'UNDERTIME_ERROR') {
              setErrorMessage(t('login.errors.undertime'));
            } else {
              error.response.data.errors.forEach(formError => {
                if (formError.type === 'field') {
                  if (formError.path === 'email') {
                    email.current.classList.add('error');
                    setDisableSubmit(true);
                  } else if (formError.path === 'orderNumber') {
                    orderNumber.current.classList.add('error');
                    setDisableSubmit(true);
                  }
                  setErrorMessage(t('login.errors.input_missing'));
                }
              });
            }
          } else {
            orderNumber.current.classList.add('error');
            email.current.classList.add('error');
            setDisableSubmit(true);
          }
          errorElement.current.classList.remove('hidden');
          if (submitButton) {
            submitButton.classList.remove('loading');
          }
        });
    }
  };

  return (
    <div className='login-form__container'>
      <span ref={errorElement} className='login-form__error hidden'>{errorMessage}</span>
      <input ref={email} className='login-form__input' type='text' placeholder={t('login.email')} onChange={patternCheck} />
      <input ref={orderNumber} className='login-form__input' type='text' placeholder={t('login.order_id')} onChange={patternCheck} />
      <button className='btn-primary login-form__submit' onClick={handleSubmit} disabled={disableSubmit}>{t('login.submit')}</button>
    </div>
  )
}

export default Login
