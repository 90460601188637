import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home/Home";
import Statistics from "./routes/Statistics/Statistics";
import NoMatchRouter from "./components/NoMatchRouter/NoMatchRouter";

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="statistics" element={<Statistics />} />

      {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
      <Route path="*" element={<NoMatchRouter />} />
    </Routes>
  );
}

export default App;
